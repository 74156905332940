export const apiConstants = {
  // Common variables

  LOGIN_BY: "manual",

  DEVICE_TYPE: "web",

  DEVICE_TOKEN: "123456",

  ERROR_CODE: [1000, 1001, 1002, 1003, 1004, 1005, 1006],

  google_api_key: "AIzaSyBwMhGADzGH8vWuzlxkTzL0JrvxVPAHtiY",

  settingsUrl:
    "https://getfarms-admin-staging.socialdraco.com/api/user/get_settings_json",

  firebase_api_key: "AIzaSyD78n3oOLfBujzFSyxi7jHlDAf0uSQA51s",

  firebase_auth_domain: "city-plot.firebaseapp.com",

  firebase_project_id: "city-plot",

  firebase_storage_bucket: "city-plot.appspot.com",

  firebase_messaging_sender_id: "1015657276489",

  firebase_app_id: "1:1015657276489:web:2adb022c2c4af4b31acb98",

  firbase_public_key:
    "BLUNv9ZktZ9_PdWH4FRgDGvX2AfABbdKIse2sIsO8ipaeEtLe_rZG2lYF8tyPvDLDk08t-8aqO6VQGij_p1t2uQ",
};
